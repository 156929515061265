import { Controller } from "stimulus"

export default class extends Controller {
  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property == 'recordName') {
        const variablesClass = 'usable_variables_in_templates'
        document.querySelectorAll(`.${variablesClass}`).forEach((element) => {
          element.style.display = 'none'
        })

        if (value !== undefined) {
          let variablesForRecordName = document.querySelector(`.${variablesClass}.${value}`)
          variablesForRecordName.style.display = 'block'
        }
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.select()
  }

  select() {
    this.state.recordName = this.variables[this.element.value]
  }

  get variables() {
    return JSON.parse(this.data.get('variables'))
  }
}
