import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['collectionTextField', 'collectionTextFieldInput']

  connect() {
    let newFieldHTML = this.collectionTextFieldTargets[
      this.collectionTextFieldTargets.length - 1
    ].outerHTML
    this.newField = document.createElement('template')
    this.newField.innerHTML = newFieldHTML

    this.toggleFieldRemovalButtons()
  }

  toggleFieldRemovalButtons() {
    this.emptyFieldInputs.forEach((fieldInput, index) => {
      let field = fieldInput.closest('.collection_text_field')
      if (field) { field.classList.add('hide_field_buttons') }
    })

    this.filledFieldInputs.forEach((fieldInput, index) => {
      let field = fieldInput.closest('.collection_text_field')
      if (field) { field.classList.remove('hide_field_buttons') }
    })
  }

  addField(event) {
    if (this.emptyFieldInputs.length == 0) {
      let newField = this.newField.cloneNode(true)
      let fieldNode = newField.content.firstChild
      this.element.appendChild(fieldNode)
      this.toggleFieldRemovalButtons()
    }
  }

  removeField(event) {
    event.preventDefault()

    let field = event.target.closest('.collection_text_field')
    if (field) {
      field.remove()
      this.toggleFieldRemovalButtons()
    }
  }

  get emptyFieldInputs() {
    return this.collectionTextFieldInputTargets.filter((input) => {
      return input.value == ''
    })
  }

  get filledFieldInputs() {
    return this.collectionTextFieldInputTargets.filter((input) => {
      return input.value != ''
    })
  }
}

