import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['postcodeField', 'cityField']

  findCityByPostcode(event) {
    if (this.hasPostcodeFieldTarget && this.hasCityFieldTarget) {
      let field = event.target
      let postcode = field.value
      let postcodesPath = this.data.get('postcodes-path')

      if (!postcode.length) { return }

      Rails.ajax({
        url: `${postcodesPath}/${postcode}`,
        type: 'get',
        success: (data) => {
          if (data) {
            setTimeout(() => { this.cityFieldTarget.value = data.body }, 0)
          }
        }
      })
    }
  }
}
