import dialogPolyfill from 'dialog-polyfill'
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    dialogPolyfill.registerDialog(this.element)

    if (this.needsDisplay) {
      this.element.showModal()
    }
  }

  close(event) {
    event.preventDefault()

    this.element.close()
    this.element.remove()
  }

  get needsDisplay() {
    return this.data.get('needs-display')
  }

  get removeOnClose() {
    return this.data.get('remove-on-close')
  }
}
