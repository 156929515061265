import { Controller } from "stimulus"
import Rails from '@rails/ujs';
import chrono from 'chrono-node'
import Moment from 'moment'
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment)

export default class extends Controller {
  static targets = [
    'filterForm',
    'startDateField',
    'storeField',
    'calendar',
    'todayButton',
    'items',
    'loader'
  ]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      let renderCalendar = () => {
        let initialDate = this.state.initialDate
        let pickedDate = this.state.pickedDate
        const numberOfMonths = parseInt(this.numberOfMonths)

        this.calendarTarget.innerHTML = this.calendarTemplate

        for (const month of Array(numberOfMonths).keys()) {
          let maxRow = 0
          let startValue = this.state.month + month
          let endValue = this.state.month + month
          let start = moment(initialDate).add(startValue, 'month').startOf('month').toDate()
          let end = moment(initialDate).add(endValue, 'month').endOf('month').toDate()
          let range = moment().range(start, end)
          let dates = Array.from(range.by('days')).map(m => m.toDate())
          let calendarGrid = this.element.querySelector(`[data-schedule-month="${month}"]`)
          let monthLabel = calendarGrid.querySelector('.month_label')

          dates.forEach((date) => {
            let currentDate = moment().format(this.dateFormat)
            let formattedDate = moment(date).format(this.dateFormat)
            let datasetDate = moment(date).format(this.dateFormat)
            let coordinates = this.getCoordinates(date)
            let row = calendarGrid.querySelector(`[data-schedule-row="${coordinates.row}"]`)
            let column = row.querySelector(`[data-schedule-column="${coordinates.column}"]`)
            let dayFromItems = this.state.items.find(day => day.date == datasetDate)
            let datePickerElement = document.createElement('a')
            datePickerElement.href = "#"
            datePickerElement.innerText = moment(date).format("D")
            datePickerElement.dataset.date = datasetDate
            datePickerElement.dataset.action = 'drop->schedule#dropItem dragover->schedule#prepareDrop dragleave->schedule#cancelDrop click->schedule#pickDate'
            datePickerElement.classList.add('date_label')

            if (dayFromItems && dayFromItems.items.length > 0) {
              datePickerElement.classList.add('has_items')
            }
            if (currentDate == formattedDate) datePickerElement.classList.add('today')
            if (pickedDate == formattedDate) datePickerElement.classList.add('picked_date')

            column.innerHTML = datePickerElement.outerHTML
            maxRow = coordinates.row
          })

          monthLabel.innerHTML = moment(start).format("MMMM")

          if (maxRow < 5) {
            calendarGrid.querySelector(`[data-schedule-row="5"]`).remove()
          }

          this.calendarTarget.querySelectorAll('.date_label.placeholder')
            .forEach(label => label.remove())

          let displayTodayButton = this.state.month == 0 ? 'none' : 'inherit'
          this.todayButtonTarget.style.display = displayTodayButton
        }
      }

      if (property === 'month' || property === 'updateTimestamp') {
        renderCalendar()
      }

      if (property === 'pickedDate') {
        const dateLabel = this.calendarTarget.querySelector(`[data-date="${value}"]`)
        const dateLabels = this.calendarTarget.querySelectorAll('.date_label')
        dateLabels.forEach(dateLabel => dateLabel.classList.remove('picked_date'))
        if (dateLabel) dateLabel.classList.add('picked_date')

        this.startDateFieldTarget.value = value
      }

      if (property === 'listedItems') {
        this.itemsTarget.innerHTML = ''

        value.forEach((day, index) => {
          let groupElement = document.createElement('div')
          let headerElement = document.createElement('h3')
          let listElement = document.createElement('ul')
          let newItemActionsElement = document.createElement('div')

          if (day.items.length == 0 && index != 0) return

          newItemActionsElement.innerHTML = this.newItemActions(day.date)
          newItemActionsElement.classList.add('schedule_group_actions')

          if (day.items.length) {
            day.items.forEach(item => {
              let itemElement = document.createElement('li')
              itemElement.innerHTML = item.html
              listElement.appendChild(itemElement)
            })
          }

          if (day.items.length == 0 && index == 0) {
            let itemElement = document.createElement('li')

            itemElement.classList.add('schedule_item')
            itemElement.classList.add('empty_group_message')
            itemElement.innerHTML = this.noItemsText
            listElement.appendChild(itemElement)
          }

          groupElement.classList.add('schedule_item_group')
          listElement.classList.add('plain')
          listElement.classList.add('schedule_items')
          headerElement.classList.add('schedule_item_group_title')
          headerElement.innerHTML = day.localized

          groupElement.appendChild(headerElement)
          groupElement.appendChild(listElement)
          if (index == 0) groupElement.appendChild(newItemActionsElement)

          this.itemsTarget.appendChild(groupElement)
        })
      }

      if (property === 'loading') {
        let display = value ? 'flex' : 'none'
        this.loaderTarget.style.display = display
      }

      return true
    }
  }

  initialize() {
    this.calendarTemplate = this.calendarTarget.innerHTML
    this.state = new Proxy({}, this.renderer)
    this.state.initialDate = moment(this.initialDate).format(this.dateFormat)
    this.state.pickedDate = this.state.initialDate
    this.loadItems(this.calculateWindowStart(0), this.storeID).then(items => {
      this.state.items = items
      this.state.month = 0
      this.listItems()
    })
  }

  currentMonth(event) {
    event.preventDefault()

    let offset = 0
    this.state.pickedDate = moment().format(this.dateFormat)
    this.loadItems(this.calculateWindowStart(offset), this.storeID).then(items => {
      this.state.items = items
      this.state.month = offset
      this.listItems()
      this.pushHistoryState()
    })
  }

  nextMonth(event) {
    event.preventDefault()

    let offset = this.state.month + 1
    this.loadItems(this.calculateWindowStart(offset), this.storeID).then(items => {
      this.state.items = items
      this.state.month = offset
    })
  }

  previousMonth() {
    event.preventDefault()

    let offset = this.state.month - 1
    this.loadItems(this.calculateWindowStart(offset), this.storeID).then(items => {
      this.state.items = items
      this.state.month = offset
    })
  }

  pickDate(event) {
    event.preventDefault()

    this.state.pickedDate = event.target.dataset.date
    this.listItems()
    this.pushHistoryState()
  }

  dragItem(event) {
    let updatePath = event.currentTarget.dataset.updatePath
    event.dataTransfer.setData('updatePath', updatePath)
  }

  dropItem(event) {
    event.preventDefault()
    event.currentTarget.classList.add('dropped')
    event.currentTarget.classList.remove('drag_entered')

    let date = event.currentTarget.dataset.date
    let updatePath = event.dataTransfer.getData('updatePath')
    updatePath = updatePath.replace('today', date)

    if (date && updatePath) {
      this.state.loading = true

      Rails.ajax({
        url: updatePath,
        type: 'put',
        dataType: 'json',
        success: (data) => {
          let offset = this.state.month
          this.loadItems(this.calculateWindowStart(offset), this.storeID).then(items => {
            this.state.items = items
            this.state.updateTimestamp = new Date()
            this.listItems()
          })
        }
      })
    }
  }

  prepareDrop() {
    event.preventDefault()
    event.currentTarget.classList.add('drag_entered')
  }

  cancelDrop(event) {
    event.currentTarget.classList.remove('drag_entered')
  }

  filter(event) {
    let formData = new FormData(this.filterFormTarget)
    let urlParams = new URLSearchParams(formData).toString()
    Turbolinks.visit(`${this.filterFormTarget.action}?${urlParams}`)
  }

  loadItems(windowStart, storeID) {
    this.state.loading = true

    return new Promise((resolve, reject) => {
      fetch(`${this.path}?start_date=${windowStart}&store_id=${storeID}`)
        .then(response => {
          let items = response.json()
          this.state.loading = false
          resolve(items)
        })
    })
  }

  listItems() {
    let items = []
    this.state.items.forEach(day => {
      let date = moment(day.date)
      if (date.isBefore(this.state.pickedDate)) return

      items.push(day)
    })

    this.state.listedItems = items.sort((left, right) => {
      let itemLeft = moment(left.date).toDate()
      let itemRight = moment(right.date).toDate()
      if (itemLeft < itemRight) return -1
      if (itemLeft > itemRight) return 1
      return 0
    })
  }

  pushHistoryState() {
    let currentPage = `${window.location.pathname}?start_date=${this.state.pickedDate}&store_id=${this.storeID}`
    history.pushState({ turbolinks: true, url: currentPage }, '', currentPage)
  }

  calculateWindowStart(offset) {
    return moment(this.state.initialDate)
      .add(offset, 'month')
      .startOf('month')
      .format(this.dateFormat)
  }

  getCoordinates(date) {
    let coordinates = {}
    let day = date.getDate()
    day += (date.getDay() == 0 ? 0 : 7 - date.getDay())
    coordinates.row = Math.ceil(parseFloat(day) / 7) - 1
    coordinates.column = ((date.getDay() + 6) % 7)
    return coordinates
  }


  newItemActions(date) {
    let regexp = /due_at=today/g
    return this.data.get('new-item-actions').replaceAll(regexp, `due_at=${date}`)
  }

  get storeID() {
    return this.storeFieldTarget.value
  }

  get dateFormat() {
    return "YYYY-MM-DD"
  }

  get numberOfMonths() {
    return this.data.get('number-of-months')
  }

  get noItemsText() {
    return this.data.get('no-items-text')
  }

  get path() {
    return this.data.get('path')
  }

  get initialDate() {
    return this.data.get('initial-date') || new Date()
  }
}
