import dialogPolyfill from 'dialog-polyfill'
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.dialogElement = document.querySelector(this.dialogSelector)
    dialogPolyfill.registerDialog(this.dialogElement)
  }

  get dialogSelector() {
    return this.data.get('dialogSelector') || ''
  }

  present(event) {
    event.preventDefault()

    if (this.dialogElement) {
      this.dialogElement.showModal()
    }
  }
}
