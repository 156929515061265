import { Controller } from "stimulus"
import QRCode from "qrcode"

export default class extends Controller {
  connect() {
    QRCode.toString(this.url, { type: "svg" }, (error, svg) => {
      if (error) throw error
      this.element.innerHTML = svg
    })
  }

  get url() {
    let url = this.data.get('url')
    return url == undefined ? url : location.href
  }
}
