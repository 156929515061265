export default {
  up: (element, duration = 150) => {
    element.style.transitionProperty = 'height, margin, padding'
    element.style.transitionDuration = `${duration}ms`
    element.style.boxSizing = 'border-box'
    element.style.height = `${element.offsetHeight}px`
    element.offsetHeight
    element.style.overflow = 'hidden'
    element.style.height = 0
    element.style.paddingTop = 0
    element.style.paddingBottom = 0
    element.style.marginTop = 0
    element.style.marginBottom = 0

    window.setTimeout(() => {
      element.style.display = 'none'
      element.style.removeProperty('height')
      element.style.removeProperty('padding-top')
      element.style.removeProperty('padding-bottom')
      element.style.removeProperty('margin-top')
      element.style.removeProperty('margin-bottom')
      element.style.removeProperty('overflow')
      element.style.removeProperty('transition-duration')
      element.style.removeProperty('transition-property')
    }, duration)
  },
  down: (element, duration = 150) => {
    element.style.removeProperty('display')

    let display = window.getComputedStyle(element).display
    if (display === 'none') {
      display = 'block'
    }

    element.style.display = display
    let height = element.offsetHeight
    element.style.overflow = 'hidden'
    element.style.height = 0
    element.style.paddingTop = 0
    element.style.paddingBottom = 0
    element.style.marginTop = 0
    element.style.marginBottom = 0
    element.offsetHeight
    element.style.boxSizing = 'border-box'
    element.style.transitionProperty = "height, margin, padding"
    element.style.transitionDuration = `${duration}ms`
    element.style.height = `${height}px`
    element.style.removeProperty('padding-top')
    element.style.removeProperty('padding-bottom')
    element.style.removeProperty('margin-top')
    element.style.removeProperty('margin-bottom')

    window.setTimeout(() => {
      element.style.removeProperty('height')
      element.style.removeProperty('overflow')
      element.style.removeProperty('transition-duration')
      element.style.removeProperty('transition-property')
    }, duration)
  },
  toggle: (element, duration = 150) => {
    if (window.getComputedStyle(element).display === 'none') {
      return global.utils.slide.down(element, duration)
    } else {
      return global.utils.slide.up(element, duration)
    }
  }
}
