import consumer from "./consumer"
import Rails from '@rails/ujs';

global.channels = {}
global.channels.projectTimeline = {
  subscribe: (projectID) => {
    return consumer.subscriptions.create({ channel: 'ProjectTimelineChannel', id: projectID }, {
      connected() {
        this.toggleNoActivityOnboarding()
      },

      disconnected() {},

      received(data) {
        if (data.event == 'update' && data.id != undefined && data.url != undefined) {
          let itemID = data.id
          let highlight = data.highlight

          Rails.ajax({
            url: `${data.url}`,
            type: 'get',
            success: (data) => {
              let itemElement = this.timelineElement.querySelector(`#${itemID}`)

              if (highlight != false) {
                global.utils.highlight(itemElement)
              }
              this.toggleNoActivityOnboarding()
            }
          })
        }

        if (data.event == 'destroy' && data.id != undefined) {
          let itemID = data.id
          let itemElement = this.timelineElement.querySelector(`#${itemID}`)
          global.utils.fadeOutAndRemove(itemElement, () => {
            this.toggleNoActivityOnboarding()
          })
        }

      },

      toggleNoActivityOnboarding() {
        let itemElements = this.timelineElement
          .querySelectorAll('.activity:not(.new_timeline_item)')
        let displayStyle = itemElements.length == 0 ? 'block' : 'none'
        this.noActivityOnboardingElement.style.display = displayStyle
      },

      get timelineElement() {
        return document.querySelector(`#project_${projectID} .timeline`)
      },

      get noActivityOnboardingElement() {
        return this.timelineElement.querySelector('.no_activity_onboarding')
      },
    })
  }
}
