import { Controller } from "stimulus"
import { Sortable } from '@shopify/draggable';

const upKey = 38
const downKey = 40
const leftKey = 37
const rightKey = 39
const enterKey = 13
const deleteKey = 8
const navigationKeys = [upKey, downKey, enterKey, leftKey, rightKey]

export default class extends Controller {
  static targets = [
    'searchField',
    'searchFieldInner',
    'searchInput',
    'token',
    'searchResults',
    'searchResultTemplate',
    'searchResultGroupTemplate',
    'tableSettingsPopover',
    'columnToggles',
    'columnToggle'
  ]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === 'searchResults') {
        let currentGroup = ''
        this.searchResultsTarget.innerHTML = ''

        value.forEach((item, index) => {
          if (currentGroup != item.name) {
            let resultGroupCell = document.importNode(
              this.searchResultGroupTemplateTarget.content.querySelector('li'), true
            )
            resultGroupCell.innerText = item.name
            currentGroup = item.name
            this.searchResultsTarget.appendChild(resultGroupCell)
          }

          let resultCell = document.importNode(
            this.searchResultTemplateTarget.content.querySelector('li'), true
          )
          resultCell.innerText = item.label
          resultCell.dataset.index = index

          this.searchResultsTarget.appendChild(resultCell)
        })

        this.state.searchResults.length ? this.openSearchResults() : this.closeSearchResults()
      }

      if (property === 'tokens') {
        this.tokenTargets.forEach(t => t.remove())
        value.forEach((tokenNode, index) => {
          tokenNode.dataset.tokenIndex = index
          this.searchInputTarget.before(tokenNode)
        })

        const placeholder = this.hasTokenTarget ? '' : this.state.defaultPlaceholder
        this.searchInputTarget.setAttribute('placeholder', placeholder)
      }

      if (property === 'currentSearchResultIndex') {
        let results = this.searchResultsTarget.querySelectorAll('li.token_search_result') || []

        if (results.length) {
          results.forEach((result, index) => {
            result.classList.toggle('highlight', index == value)
          })
        }
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.defaultPlaceholder = this.searchInputTarget.getAttribute('placeholder')
    this.state.searchResults = []
    this.state.tokens = this.tokenTargets || []
    this.searchInputDelay = undefined
    this.columnTogglesSortable = undefined
    this.resetCurrentSearchResult()
    this.initializeColumnTogglesSortable()
  }

  disconnect() {
    if (typeof this.columnTogglesSortable.destroy === 'function') {
      this.columnTogglesSortable.destroy()
    }
  }

  initializeColumnTogglesSortable() {
    this.columnTogglesSortable = new Sortable(this.columnTogglesTarget, {
      draggable: 'li',
      mirror: { constrainDimensions: true }
    })
  }

  addToken(token) {
    const selectedIndex = token.dataset.index
    const result = this.state.searchResults[selectedIndex]

    if (result == undefined) { return }

    let template = document.createElement('template')
    let html = result.html.trim()
    template.innerHTML = html;
    let resultNode = template.content.firstChild
    this.state.tokens = [...this.state.tokens, resultNode]
    this.state.searchResults = []
    this.searchInputTarget.value = ""
    this.resetCurrentSearchResult()
    this.focusSearchInput()
  }

  deleteToken(token) {
    let tokenIndex = token.dataset.tokenIndex
    let tokens = this.state.tokens
    tokens.splice(tokenIndex, 1)
    this.state.tokens = tokens
    this.resetCurrentSearchResult()
    this.focusSearchInput()
  }

  // Events

  selectResult(event) {
    const selectedToken = event.target
    this.addToken(selectedToken)

    event.preventDefault()
  }

  highlightResult(event) {
    const highlightedIndex = event.target.dataset.index
    this.state.currentSearchResultIndex = highlightedIndex
  }

  resetCurrentSearchResult(event) {
    this.state.currentSearchResultIndex = -1
  }

  deleteSelectedToken(event) {
    const token = event.target.closest('.token')
    this.deleteToken(token)

    event.preventDefault()
  }

  navigateSearchResults(event) {
    switch (event.keyCode) {
      case upKey:
        event.preventDefault()

        if (this.state.currentSearchResultIndex > 0) {
          this.state.currentSearchResultIndex--
        }
        break
      case downKey:
        event.preventDefault()

        if (this.state.currentSearchResultIndex < this.state.searchResults.length - 1) {
          this.state.currentSearchResultIndex++
        }
        break
      case enterKey:
        if (this.state.currentSearchResultIndex != -1) {
          event.preventDefault()
        }

        let result = this.searchResultsTarget.querySelector(
          `li.token_search_result[data-index="${this.state.currentSearchResultIndex}"]`
        )
        if (result) { result.click() }
        break
      case deleteKey:
        if (event.target.value.length == 0 && this.state.tokens.length) {
          this.deleteToken(this.state.tokens[this.state.tokens.length - 1])
        }
        break
    }
  }

  openSearchResults(event) {
    let searchResultsWidth = this.searchInputTarget.offsetWidth
    let searchResultsTop = this.searchInputTarget.offsetTop + this.searchInputTarget.offsetHeight
    let searchResultsLeft = this.searchInputTarget.offsetLeft

    this.searchResultsTarget.style.width = `${searchResultsWidth}px`
    this.searchResultsTarget.style.top = `${searchResultsTop}px`
    this.searchResultsTarget.style.left = `${searchResultsLeft}px`
    this.searchResultsTarget.classList.add('focused')
  }

  closeSearchResults(event) {
    if (event && this.searchFieldInnerTarget.contains(event.target)) { return }
    this.searchResultsTarget.classList.remove('focused')
  }

  search(event) {
    clearTimeout(this.searchInputDelay)

    this.searchInputDelay = setTimeout(() => {
      if (!navigationKeys.includes(event.keyCode)) {
        (async () => {
          if (this.term.length == 0) {
            this.state.searchResults = []
            this.resetCurrentSearchResult()
            return
          }

          const source = await fetch(`${this.tokensPath}?model=${this.modelName}&term=${this.term}`)
          this.state.searchResults = await source.json()
          this.resetCurrentSearchResult()
        })()
      }
    }, 300)
  }

  submit(event) {
    event.preventDefault()

    let formData = new FormData(this.element)

    if (this.term) {
      formData.set('tokens[][column]', 'search')
      formData.set('tokens[][value]', this.term)
    }

    let urlParams = new URLSearchParams(formData).toString()
    Turbolinks.visit(`${this.element.action}?${urlParams}`)
  }

  focusSearchInput(event) {
    this.searchInputTarget.focus()
  }

  focusSearchField(event) {
    this.searchFieldTarget.classList.add('focused')
  }

  blurSearchField(event) {
    this.searchFieldTarget.classList.remove('focused')

    setTimeout(() => this.state.searchResults = [], 300)
  }

  dismissTableSettingsPopover(event) {
    event.preventDefault()

    if (this.hasTableSettingsPopoverTarget) {
      this.tableSettingsPopoverTarget.classList.remove('show')
    }
  }

  // Getters

  get term() {
    return this.searchInputTarget.value
  }

  get modelName() {
    return this.data.get('model-name')
  }

  get tokensPath() {
    return this.data.get('tokens-path')
  }
}

