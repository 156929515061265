import { Controller } from "stimulus"
import { Draggable, Sortable } from '@shopify/draggable';
import Rails from "@rails/ujs";
import Mousetrap from "mousetrap"

const upKey = 38
const downKey = 40
const leftKey = 37
const rightKey = 39
const enterKey = 13
const deleteKey = 8
const escapeKey = 27
const navigationKeys = [upKey, downKey, enterKey, leftKey, rightKey]

export default class extends Controller {
  static targets = ["searchBar", "searchResults", "bookmarks", "bookmark"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === 'readyForSearch') {
        if (this.state.readyForSearch === true) {
          this.element.classList.add("ready_for_search")
          this.searchBarTarget.focus()
        } else {
          this.element.classList.remove("ready_for_search")
          this.searchBarTarget.value = ""
          this.state.searchResults = []
          this.resetCurrentSearchResult()
        }
      }

      if (property === 'searchResults') {
        this.searchResultsTarget.innerHTML = ""

        this.state.searchResults.forEach((item, index) => {
          let searchResultElement = item.cloneNode(true)
          searchResultElement.dataset.index = index
          searchResultElement.dataset.action = "mouseenter->sidebar#highlightResult mouseleave->sidebar#resetCurrentSearchResult"
          this.searchResultsTarget.appendChild(searchResultElement)
        })

        if (this.searchBarTarget.value) {
          this.element.classList.add("searching")
        } else {
          this.element.classList.remove("searching")
        }
      }

      if (property === 'currentSearchResultIndex') {
        let results = this.searchResultsTarget.querySelectorAll('li') || []

        if (results.length) {
          results.forEach((result, index) => {
            result.classList.toggle('highlight', index == value)
          })
        }
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.readyForSearch = false
    this.state.searchResults = []
    this.bookmarksSortable = undefined

    this.initializeBookmarksSortable()
    this.initializeSearch()
  }

  disconnect() {
    if (typeof this.bookmarksSortable.destroy === 'function') {
      this.bookmarksSortable.destroy()
    }
  }

  initializeBookmarksSortable() {
    // Disable automatic tabindex on bookmarks div.
    delete Draggable.Plugins.Focusable

    this.bookmarksSortable = new Sortable(this.bookmarksTarget, {
      draggable: 'li',
      mirror: { constrainDimensions: true }
    })

    this.bookmarksSortable.on('sortable:stop', (event) => {
      const container = this.bookmarksSortable.containers[0]
      const sortedElements = this.bookmarksSortable.getDraggableElementsForContainer(container)
      const sortedIDs = sortedElements.map((element) => element.dataset.id)

      Rails.ajax({
        url: this.sortBookmarksPath,
        type: 'post',
        data: sortedIDs.map((id) => `bookmark[]=${id}`).join("&")
      })
    })
  }

  initializeSearch() {
    this.initializeMousetrap()

    Mousetrap.bindGlobal(['command+k', 'ctrl+k'], () => {
      this.state.readyForSearch = true
    })
  }

  highlightResult(event) {
    const highlightedIndex = event.target.dataset.index
    this.state.currentSearchResultIndex = highlightedIndex
  }

  resetCurrentSearchResult(event) {
    this.state.currentSearchResultIndex = -1
  }

  navigateSearchResults(event) {
    switch (event.keyCode) {
      case upKey:
        event.preventDefault()

        if (this.state.currentSearchResultIndex > 0) {
          this.state.currentSearchResultIndex--
        }
        break
      case downKey:
        event.preventDefault()

        if (this.state.currentSearchResultIndex < this.state.searchResults.length - 1) {
          this.state.currentSearchResultIndex++
        }
        break
      case enterKey:
        if (this.state.currentSearchResultIndex != -1) {
          event.preventDefault()
        }

        let result = this.searchResultsTarget.querySelector(
          `li[data-index="${this.state.currentSearchResultIndex}"] .plain_button`
        )
        if (result) { result.click() }
        break
      case escapeKey:
        this.state.readyForSearch = false
        break
    }
  }

  search(event) {
    if (!navigationKeys.includes(event.keyCode)) {
      const searchValue = this.searchBarTarget.value

      if (searchValue == 0) {
        this.state.searchResults = []
        this.resetCurrentSearchResult()
        return
      }

      let searchResults = []

      this.element.querySelectorAll('.searchable_items li').forEach((element) => {
        const regexp = new RegExp(searchValue, 'i')
        const elementText = element.querySelector('.plain_button span').innerText.trim()

        if (elementText.search(regexp) !== -1) {
          element.querySelector('.plain_button').classList.remove("active_in_sidebar")
          const editButtonElement = element.querySelector('.edit_button')
          if (editButtonElement) editButtonElement.remove()

          searchResults.push(element)
        }
      })

      this.state.searchResults = searchResults
      this.resetCurrentSearchResult()
    }
  }

  initializeMousetrap() {
    var globalCallbacks = {}
    var originalStopCallback = Mousetrap.prototype.stopCallback

    Mousetrap.prototype.stopCallback = function(e, element, combo, sequence) {
      var self = this

      if (self.paused) {
        return true
      }

      if (globalCallbacks[combo] || globalCallbacks[sequence]) {
        return false
      }

      return originalStopCallback.call(self, e, element, combo)
    }

    Mousetrap.prototype.bindGlobal = function(keys, callback, action) {
      var self = this
      self.bind(keys, callback, action)

      if (keys instanceof Array) {
        for (var i = 0; i < keys.length; i++) {
          globalCallbacks[keys[i]] = true
        }
        return
      }

      globalCallbacks[keys] = true
    }

    Mousetrap.init()
  }

  get sortBookmarksPath() {
    return this.data.get('sort-bookmarks-path')
  }
}
