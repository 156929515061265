import { Controller } from "stimulus"

export default class extends Controller {
  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === 'hash') {
        const elementFromHash = document.querySelector(this.state.hash)

        if (elementFromHash) {
          global.utils.highlight(elementFromHash)
        }
      }

      return true
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this.state.projectTimelineChannel = global.channels.projectTimeline.subscribe(this.projectID)
    this.state.hash = window.location.hash
  }

  disconnect() {
    if (this.state.projectTimelineChannel != undefined) {
      this.state.projectTimelineChannel.unsubscribe()
    }
  }

  get projectID() {
    return this.data.get('id')
  }
}
