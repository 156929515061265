import { Controller } from "stimulus"

const upKey = 38
const downKey = 40
const leftKey = 37
const rightKey = 39
const enterKey = 13
const deleteKey = 8
const navigationKeys = [upKey, downKey, enterKey, leftKey, rightKey]
const resultsClass = 'autocomplete_results'

export default class extends Controller {
  static targets = [
    'searchField',
    'searchResults',
    'searchResultTemplate'
  ]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === 'searchResults') {
        this.searchResultsTarget.innerHTML = ''

        value.forEach((item, index) => {
          let resultCell = document.importNode(
            this.searchResultTemplateTarget.content.querySelector('li'), true
          )
          resultCell.innerHTML = item.html
          resultCell.dataset.index = index

          this.searchResultsTarget.appendChild(resultCell)
        })

        if (value.length > 0) {
          this.openSearchResults()
        } else {
          this.closeSearchResults()
        }
      }

      if (property === 'currentSearchResultIndex') {
        let results = this.searchResultsTarget.querySelectorAll('li.search_result') || []

        if (results.length) {
          results.forEach((result, index) => {
            result.classList.toggle('highlight', index == value)
          })
        }
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.searchResults = []
    this.searchInputDelay = undefined
    this.resetCurrentSearchResult()
  }

  // Events

  selectResult(event) {
    const selectedIndex = event.currentTarget.dataset.index
    const result = this.state.searchResults[selectedIndex]

    if (result == undefined) { return }

    this.searchFieldTarget.value = result[this.resultAttribute]
    this.state.searchResults = []
    this.resetCurrentSearchResult()

    event.preventDefault()
  }

  highlightResult(event) {
    const highlightedIndex = event.target.dataset.index
    this.state.currentSearchResultIndex = highlightedIndex
  }

  resetCurrentSearchResult(event) {
    this.state.currentSearchResultIndex = -1
  }

  navigateSearchResults(event) {
    switch (event.keyCode) {
      case upKey:
        event.preventDefault()

        if (this.state.currentSearchResultIndex > 0) {
          this.state.currentSearchResultIndex--
        }
        break
      case downKey:
        event.preventDefault()

        if (this.state.currentSearchResultIndex < this.state.searchResults.length - 1) {
          this.state.currentSearchResultIndex++
        }
        break
      case enterKey:
        if (this.state.currentSearchResultIndex != -1) {
          event.preventDefault()
        }

        let result = this.searchResultsTarget.querySelector(
          `li.search_result[data-index="${this.state.currentSearchResultIndex}"]`
        )
        if (result) { result.click() }
        break
    }
  }

  openSearchResults(event) {
    let searchResultsWidth = this.searchFieldTarget.offsetWidth
    let searchResultsTop = this.searchFieldTarget.offsetTop + this.searchFieldTarget.offsetHeight
    let searchResultsLeft = this.searchFieldTarget.offsetLeft

    this.searchResultsTarget.style.width = `${searchResultsWidth}px`
    this.searchResultsTarget.style.top = `${searchResultsTop}px`
    this.searchResultsTarget.style.left = `${searchResultsLeft}px`
    this.searchResultsTarget.classList.add('focused')
  }

  closeSearchResults(event) {
    this.searchResultsTarget.classList.remove('focused')
  }

  search(event) {
    clearTimeout(this.searchInputDelay)

    this.searchInputDelay = setTimeout(() => {
      if (!navigationKeys.includes(event.keyCode)) {
        (async () => {
          if (this.query.length == 0) {
            this.state.searchResults = []
            this.resetCurrentSearchResult()
            return
          }

          const source = await fetch(`${this.searchPath}?query=${this.query}`)
          this.state.searchResults = await source.json()
          this.resetCurrentSearchResult()
        })()
      }
    }, 300)
  }

  focusSearchField(event) {
    if (this.state.searchResults.length > 0) {
      this.searchResultsTarget.classList.add('focused')
    }
  }

  blurSearchField(event) {
    setTimeout(() => this.searchResultsTarget.classList.remove('focused'), 300)
  }

  // Getters

  get query() {
    return this.searchFieldTarget.value
  }

  get searchPath() {
    return this.data.get('search-path')
  }

  get resultAttribute() {
    return this.data.get('attribute') || 'value'
  }
}
