import Rails from '@rails/ujs';
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['createButton']

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property == 'name') {
        let display = value == '' ? 'none' : 'flex'
        this.createButtonTarget.style.display = display
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.name = ''
  }

  handleKeyup(event) {
    this.state.name = event.target.value
  }

  create(event) {
    event.preventDefault()

    Rails.ajax({
      url: `${this.createButtonTarget.href}.json`,
      type: 'POST',
      data: `product[name]=${this.state.name}`,
      success: (data) => {
        if (data.id) {
          this.autocompleteSelectController.state.selectedItem = data
          this.autocompleteSelectController.state.isSearching = false
        }
      }
    })
  }

  get autocompleteSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element, "autocomplete-select"
    )
  }
}
