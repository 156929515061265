import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event
      const { id, file } = detail
      const element = document.querySelector('.notices')

      element.insertAdjacentHTML("afterbegin", `
        <div id="direct_upload_${id}" class="notice direct_upload">
          <div id="upload_progress_${id}" class="upload_progress" style="width: 0%"></div>
          <span class="filename">${file.name}</span>
        </div>
      `)
    })

    addEventListener("direct-upload:start", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct_upload_${id}`)
    })

    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      const element = document.getElementById(`direct_upload_${id}`)
      const progressElement = document.getElementById(`upload_progress_${id}`)
      element.classList.add("uploading")
      progressElement.style.width = `${progress}%`
    })

    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct_upload_${id}`)
      element.classList.add("error")
      element.classList.remove("uploading")
      element.setAttribute("title", error)
    })

    addEventListener("direct-upload:end", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct_upload_${id}`)
      element.classList.add("completed")
      element.classList.remove("uploading")

      if (!element.classList.contains('error')) {
        setTimeout(() => {
          element.classList.add('closed')
        }, 500)
      }
    })
  }
}
