function camelize(string) {
  return string.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  })
}

const utils = require.context('.', true, /_util\.js$/)
global.utils = {}
utils.keys().forEach((key) => {
  var functionName = camelize(key.replace('./', '').replace('_util.js', ''))
  global.utils[functionName] = utils(key).default
})
