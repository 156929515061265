import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sourceField", "notice"]

  copy(event) {
    event.preventDefault()

    if (this.hasSourceFieldTarget) {
      this.sourceFieldTarget.select()
      document.execCommand("copy")
    }

    if (this.hasNoticeTarget) {
      let noticesElement = document.querySelector(".notices")
      let noticeElement = this.noticeTarget.cloneNode(true)
      noticesElement.innerHTML = ""
      noticesElement.appendChild(noticeElement.content)
    }
  }
}
