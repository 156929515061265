import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  close(event) {
    event.preventDefault()

    utils.slide.up(this.element)
  }
}
