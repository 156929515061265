import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['movementField', 'pricePerWeightField', 'weightField', 'totalPriceLabel']

  connect() {
    this.calculate()
  }

  calculate(event) {
    if (this.isUnableToCalculate) return

    const pricePerWeight = parseFloat(this.pricePerWeightFieldTarget.value || 0)
    const weight = parseFloat(this.weightFieldTarget.value || 0)
    let total = Math.round(pricePerWeight * weight)

    if (this.isOutgoing) total = total * -1

    this.totalPriceLabelTarget.innerText = `${total.toLocaleString(this.locale)} ${this.symbolValue}`
  }

  get symbolValue() {
    return this.element.dataset.projectItemTotalPriceSymbolValue
  }

  get locale() {
    return document.body.dataset.locale
  }

  get isOutgoing() {
    return this.movementFieldTarget.value == 'outgoing'
  }

  get isUnableToCalculate() {
    return !this.hasTotalPriceLabelTarget || !this.hasPricePerWeightFieldTarget || !this.hasWeightFieldTarget
  }
}
