import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  assign(event) {
    const selectedIndex = event.currentTarget.dataset.index
    const result = this.autocompleteController.state.searchResults[selectedIndex]

    if (result == undefined) { return }

    Rails.ajax({
      url: result.assign_path,
      type: result.assign_path_method
    })

    event.preventDefault()
  }

  get autocompleteController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element, "autocomplete"
    )
  }
}
