import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'field',
    'fieldInput',
    'fieldValueInput',
    'searchField',
    'searchFieldInput'
  ]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property == 'isSearching') {
        if (value == true) {
          this.fieldTarget.style.display = 'none'
          this.searchFieldTarget.style.display = 'initial'
          setTimeout(() => { this.searchFieldInputTarget.focus() }, 50)
        } else {
          this.fieldTarget.style.display = 'initial'
          this.searchFieldTarget.style.display = 'none'
        }
      }

      if (property == 'selectedItem') {
        this.fieldInputTarget.innerText = value.value
        this.fieldValueInputTarget.value = value.id
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.isSearching = false
  }

  selectResult(event) {
    const selectedIndex = event.currentTarget.dataset.index
    const result = this.autocompleteController.state.searchResults[selectedIndex]

    if (result == undefined) { return }

    this.state.selectedItem = result
    this.state.isSearching = false

    event.preventDefault()
  }

  startSearching(event) {
    event.preventDefault()

    this.state.isSearching = true
  }

  get autocompleteController() {
    return this.application.getControllerForElementAndIdentifier(
      this.searchFieldTarget, "autocomplete"
    )
  }
}
