import { Controller } from "stimulus"

export default class extends Controller {
  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === 'title') {
        if (this.state.title.length) {
          document.title = `${this.state.title} – ${this.defaultTitle}`
        } else {
          document.title = this.defaultTitle
        }
      }

      if (property === 'bookmarkTitle') {
        global.state.bookmarkTitle = this.state.bookmarkTitle
      }

      return true
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this.update(this.text)
  }

  update(title) {
    this.state.title = title
    this.state.bookmarkTitle = this.state.title.replace(/\((.*) db\)/, '').trim()
  }

  get text() {
    return this.element.innerText.trim()
  }

  get defaultTitle() {
    return document.querySelector('body').dataset.defaultTitle
  }
}
