import { Controller } from "stimulus"

export default class extends Controller {
  toggle(event) {
    event.preventDefault()
    utils.slide.toggle(this.targetElement)
  }

  get targetElement() {
    const selector = this.data.get("target")
    return document.querySelector(selector)
  }
}
